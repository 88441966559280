//ATTENTION!!
//Changs to these constants will liekly require a database migration
//As these values are used by the initial Database script

// Scorm formats
export const SCORM_2004 = 'SCORM2004'
export const SCORM_12 = 'SCORM12'
export const xAPI = 'xAPI'

// Languages
export const ENGLISH = 'en'
export const FRENCH = 'fr'

// Subscription Invalid Response Messages
export const SUBSCRIPTION_EXPIRED = 'Your subscription has expired. Please contact Dialectic for support.'
export const SEAT_LIMIT_EXCEEDED = 'You have exceeded the available number of seats on your subscription.'
