import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { CreateSubscription } from 'common/schema/*'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, NumberInput, SelectInput, DateInput } from '../../../components'

export function SubscriptionsCreate() {
  const navigate = useNavigate()
  const { accountId } = useParams()

  const formik = useFormik({
    initialValues: {
      accountId,
      programId: '',
      startsAt: '',
      endsAt: '',
      seats: '',
    },
    validationSchema: CreateSubscription.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const [programsData, setProgramsData] = useState([])

  async function getPrograms() {
    await axiosInstance
      .get('programs')
      .then((res) => setProgramsData(res.data.results))
      .catch((err) => console.log(`Error: ${err.response.data}`))
  }

  useEffect(() => {
    getPrograms()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post('subscriptions/create', values)
      .then(() => {
        toast.success('Subscription created successfully!')
        navigate(`/admin/accounts/${accountId}`)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Add New Subscription</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <SelectInput
            label="Program"
            name="programId"
            id="programId"
            value={formik.values['programId'] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.programId && formik.errors.programId}
            errorMessage={formik.errors.programId}
          >
            {programsData.map((program) => (
              <option key={program.id} value={program.id}>
                {program.name}
              </option>
            ))}
          </SelectInput>
          <NumberInput
            label="Seats"
            name="seats"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.seats && formik.errors.seats}
            errorMessage={formik.errors.seats}
          />
          <DateInput
            label="Start Date"
            name="startsAt"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.startsAt && formik.errors.startsAt}
            errorMessage={formik.errors.startsAt}
          />
          <DateInput
            label="Expiry Date"
            name="endsAt"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.endsAt && formik.errors.endsAt}
            errorMessage={formik.errors.endsAt}
          />
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
