import { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../axiosInstance'

import { Table, Link, Loading, Button, EditIcon } from '../../../components'

import styles from './packages-list.module.scss'

export function PackagesList() {
  const [packagesData, setPackagesData] = useState([])
  const [sorting, setSorting] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [totalRecords, setTotalRecords] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(true)
  const [showPackageNotificationForm, setShowPackageNotificationForm] = useState(false)

  async function packagesList(sorting, searchFilter, pagination) {
    const { id: sortingColumn = '', desc = false } = sorting[0] || []
    const sortingOrder = desc ? 'desc' : 'asc'
    await axiosInstance
      .post('packages/list', {
        skip: pagination.pageIndex * pagination.pageSize,
        take: pagination.pageSize,
        sort: [{ column: sortingColumn || 'packages.id', order: sortingOrder }],
        search: searchFilter,
      })
      .then((res) => {
        setPackagesData(res.data.results)
        setTotalRecords(res.data.resultCount)
      })
      .catch((err) => console.log(`Error: ${err.response.data}`))
      .finally(() => setLoading(false))
  }

  // This function (and the button below) are on hold until we can dedicate more time to it
  // Reminder: Users who have not yet downloaded any packages in a program should NOT receive the update email
  // async function sendPackageNotification() {
  //   await axiosInstance
  //     .post('packages/send-update-notification')
  //     .then(() => {
  //       toast.success('Package Notifications sent successfully!')
  //       setShowPackageNotificationForm(false)
  //     })
  //     .catch((error) => toast.error(error))
  // }

  useEffect(() => {
    packagesList(sorting, searchFilter, pagination)
  }, [sorting, searchFilter, pagination])

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        id: 'editButton',
        header: 'Edit',
        accessorKey: 'id',
        cell: (info) => (
          <Link href={`${info.getValue()}`}>
            <EditIcon />
          </Link>
        ),
        enableSorting: false,
      },
    ],
    []
  )

  if (loading) return <Loading />

  return (
    <div>
      <div className="admin_list_page_header">
        <h1>Manage Packages</h1>
        <Link href="create">Add New Package</Link>
      </div>
      <Table
        data={packagesData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        pagination={pagination}
        setPagination={setPagination}
        totalRecords={totalRecords}
      />
      {/* // This button and its functionality are on hold until we can dedicate more time to it
      // Reminder: Users who have not yet downloaded any packages in a program should NOT receive the update email
      <div className={styles.notification_form}>
        <Button
          text="Send Package Update Notification"
          icon={<EmailIcon />}
          onClick={() => setShowPackageNotificationForm((prev) => !prev)}
        />
        {showPackageNotificationForm && (
          <div className={styles.confirmation}>
            <p>
              <b>Confirm sending this notification?</b>
              <br /> Emails will go out to all users who have newly updated packages available.
            </p>
            <div className={styles.confirmation_buttons}>
              <Button text="Confirm and Send" variant="secondary" onClick={sendPackageNotification} />
              <Button text="Cancel" variant="cancel" onClick={() => setShowPackageNotificationForm(false)} />
            </div>
          </div>
        )}
      </div> */}
    </div>
  )
}
