import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, ReadOnlyInput } from '../../../components'
import { NoMatch } from '../../../pages'

export function UsersDelete() {
  const navigate = useNavigate()
  const { userId } = useParams()

  const [axiosError, setAxiosError] = useState(null)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      organization_name: '',
      role: '',
    },
    onSubmit: (values) => handleSubmit(values),
  })

  useEffect(() => {
    async function getUser() {
      await axiosInstance
        .get(`users/${userId}`)
        .then((res) => {
          formik.setFieldValue('name', res.data.name)
          formik.setFieldValue('email', res.data.email)
          formik.setFieldValue('organization_name', res.data.organization_name)
          formik.setFieldValue('role', res.data.role)
        })
        .catch((err) => {
          console.log(err)
          setAxiosError(err.response.status)
        })
    }
    getUser()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`users/${userId}/delete`, values)
      .then(() => {
        toast.success('User deleted successfully!')
        navigate('/admin/users')
      })
      .catch((err) => toast.error(`Error: ${err}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Delete User</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <ReadOnlyInput label="Name" value={formik.values['name']} />
          <ReadOnlyInput label="Email" value={formik.values['email']} />
          <ReadOnlyInput label="Account" value={formik.values['organization_name']} />
          <ReadOnlyInput label="Role" value={formik.values['role']} />
          <div className="submit_container">
            <Button type="submit" text="Confirm Delete" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
