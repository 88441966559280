import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { UpdateUser } from 'common/schema/*'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput, SelectInput, ReadOnlyInput } from '../../../components'
import { NoMatch } from '../../../pages'

export function UsersEdit() {
  const navigate = useNavigate()
  const { userId } = useParams()

  const [axiosError, setAxiosError] = useState(null)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      organization_name: '',
      role: '',
    },
    validationSchema: UpdateUser.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  useEffect(() => {
    async function getUser() {
      await axiosInstance
        .get(`users/${userId}`)
        .then((res) => {
          formik.setFieldValue('name', res.data.name)
          formik.setFieldValue('email', res.data.email)
          formik.setFieldValue('organization_name', res.data.organization_name)
          formik.setFieldValue('role', res.data.role)
        })
        .catch((err) => {
          console.log(err)
          setAxiosError(err.response.status)
        })
    }
    getUser()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`users/${userId}/update`, values)
      .then(() => {
        toast.success('User updated successfully!')
        navigate('/admin/users')
      })
      .catch((err) => toast.error(`Error: ${err}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Edit User</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <TextInput
            label="Name"
            name="name"
            value={formik.values['name']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <TextInput
            label="Email"
            name="email"
            value={formik.values['email']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.email && formik.errors.email}
            errorMessage={formik.errors.email}
          />
          <ReadOnlyInput label="Account" value={formik.values['organization_name']} />
          <SelectInput
            label="Role"
            name="role"
            id="role"
            value={formik.values['role']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.role && formik.errors.role}
            errorMessage={formik.errors.role}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </SelectInput>
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
