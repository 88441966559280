import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import DOMPurify from 'dompurify'
import { toast } from 'react-toastify'
import { UserChangePasswordRequest } from 'common/schema/*'
import { axiosInstance } from '../../axiosInstance'

import { Button, PasswordInput, Sidebar } from '../../components'

import styles from './profile.module.scss'

export function Profile() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    organization_name: '',
    resources: '',
  })
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false)

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: UserChangePasswordRequest.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(userData?.resources, { ADD_ATTR: ['target'] }),
  })

  useEffect(() => {
    async function getUserData() {
      await axiosInstance
        .get('users/profile')
        .then((res) => {
          setUserData({
            name: res.data.name,
            email: res.data.email,
            organization_name: res.data.organization_name,
            resources: res.data.resources,
          })
        })
        .catch((err) => console.log(`Error: ${err.response.data}`))
    }
    getUserData()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post('users/change-password', values)
      .then(() => {
        toast.success('Password updated successfully.')
        setShowResetPasswordForm(false)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div className={styles.profile}>
      <div className={styles.content}>
        <h1>My Profile</h1>
        <section>
          <p>
            <b>Name: </b>
            {userData.name}
          </p>
          <p>
            <b>Organization: </b>
            {userData.organization_name}
          </p>
          <p>
            <b>Email: </b>
            {userData.email}
          </p>
        </section>
        <Button text="Change Password?" onClick={() => setShowResetPasswordForm((prev) => !prev)} />
        {showResetPasswordForm && (
          <form onSubmit={formik.handleSubmit}>
            <h3>Reset Password</h3>

            <PasswordInput
              label="Current Password"
              name="currentPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasErrors={formik.touched.currentPassword && formik.errors.currentPassword}
              errorMessage={formik.errors.currentPassword}
            />

            <PasswordInput
              label="New Password"
              name="newPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasErrors={formik.touched.newPassword && formik.errors.newPassword}
              errorMessage={formik.errors.newPassword}
            />

            <PasswordInput
              label="Confirm New Password"
              name="confirmNewPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasErrors={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
              errorMessage={formik.errors.confirmNewPassword}
            />

            <div className={styles.submit_container}>
              <Button type="submit" text="Submit" variant="primary" />
            </div>
          </form>
        )}
      </div>
      <Sidebar>
        <h3>Account Resources</h3>
        <div className="richtext" dangerouslySetInnerHTML={sanitizedData()} />
      </Sidebar>
    </div>
  )
}
