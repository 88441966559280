import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styles from './input.module.scss'

export function TextInput({ label, name, value, onChange, onBlur, hasErrors, errorMessage }) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={styles.input}
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hasErrors && <p className={styles.form_error_message}>*{errorMessage}</p>}
    </div>
  )
}

export function PasswordInput({
  label = 'Password',
  name = 'password',
  value,
  onChange,
  onBlur,
  hasErrors,
  errorMessage,
}) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={styles.input}
        type="password"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hasErrors && <p className={styles.form_error_message}>*{errorMessage}</p>}
    </div>
  )
}

export function NumberInput({ label, name, value, onChange, onBlur, hasErrors, errorMessage }) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={styles.input}
        type="number"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hasErrors && <p className={styles.form_error_message}>*{errorMessage}</p>}
    </div>
  )
}

export function DateInput({ label, name, value, onChange, onBlur, hasErrors, errorMessage }) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={styles.input}
        type="date"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hasErrors && <p className={styles.form_error_message}>*{errorMessage}</p>}
    </div>
  )
}

export function SelectInput({ label, name, value, onChange, onBlur, hasErrors, errorMessage, children }) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <select
        className={styles.input}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={{ cursor: 'pointer' }}
      >
        <option disabled hidden value="">
          Choose an option
        </option>
        {children}
      </select>
      {hasErrors && <p className={styles.form_error_message}>*{errorMessage}</p>}
    </div>
  )
}

export function CheckBoxInput({ label, name, value, onChange, onBlur, hasErrors, errorMessage }) {
  return (
    <div className={styles.checkbox_input_container}>
      <div>
        <label className={styles.checkbox_label} htmlFor={name}>
          {label}
        </label>
        <input
          className={styles.input}
          type="checkbox"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
      {hasErrors && <p className={styles.form_error_message}>*{errorMessage}</p>}
    </div>
  )
}

export function RichTextInput({ label, id, value, onChange }) {
  return (
    <>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <ReactQuill id={id} value={value} onChange={onChange} />
    </>
  )
}

// The name field on this input must match the string param in the packageFileRouter upload middleware
export function FileInput({ label, onChange }) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label} htmlFor="scormFile">
        {label}
      </label>
      <input className={styles.file_input} id="scormFile" name="scormFile" type="file" onChange={onChange} />
    </div>
  )
}

export function ReadOnlyInput({ label, value }) {
  return (
    <div className={styles.input_container}>
      <label className={styles.label}>{label}</label>
      <input className={styles.input} type="text" value={value} disabled />
    </div>
  )
}
