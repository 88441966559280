import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { UserAcceptTOSRequest } from 'common/schema/*'
import { axiosInstance } from '../../axiosInstance'

import { Button, Link, CheckBoxInput } from '../../components'

import styles from './terms-of-service.module.scss'

export function TermsOfService() {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      acceptTermsOfService: false,
    },
    validationSchema: UserAcceptTOSRequest.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  async function handleSubmit(values) {
    await axiosInstance
      .post('acceptTOS', values)
      .then(() => {
        toast.success('Thank you for accepting our Terms of Service!')
        navigate('/account')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div className={styles.tos_page}>
      <h1>Dialectic Skill Stream</h1>
      <h2>Terms of Service</h2>
      <h4>Please read carefully before proceeding.</h4>
      <p>
        Unauthorized copying, dissemination, or any other use of the materials on this site is strictly prohibited
        without the express written permission of Dialectic. Any personal data you provide us with in the context of
        your response or request for information will only be used in accordance with our{' '}
        <Link href="https://dialectic.solutions/dss-privacy-statement/" variant="textLink" newTab={true}>
          privacy statement
        </Link>{' '}
        privacy statement and our{' '}
        <Link href="https://dialectic.solutions/dss-terms-of-user/" variant="textLink" newTab={true}>
          terms of use
        </Link>
        .
      </p>
      <p>
        Should you have any inquiries or encounter accessibility issues with our website, feel free to reach out to us.
        Your questions and concerns are important to us, and we are here to assist you.
      </p>
      <p>
        At Dialectic, we are dedicated to maintaining the accuracy of our website. If you come across any information
        either on the website or within the zip files that is incorrect or outdated, we kindly ask you to inform us.
        Please specify the location on the website where you found the information, and we will promptly address it.
      </p>
      <p>
        Please communicate with us via email at:{' '}
        <a className={styles.email_link} href="mailto:hello@dialecticskillstream.com">
          hello@dialecticskillstream.com
        </a>
      </p>
      <form onSubmit={formik.handleSubmit}>
        <CheckBoxInput
          label="Accept Terms of Service"
          name="acceptTermsOfService"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasErrors={formik.touched.acceptTermsOfService && formik.errors.acceptTermsOfService}
          errorMessage={formik.errors.acceptTermsOfService}
        />
        <div className={styles.submit_container}>
          <Button text="Submit" type="submit" />
        </div>
      </form>
    </div>
  )
}
