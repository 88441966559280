import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { UpdateProgram } from 'common/schema/*'

import { axiosInstance } from '../../../axiosInstance'
import {
  Button,
  TextInput,
  RichTextInput,
  Link,
  Table,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  SelectInput,
  TrashIcon,
} from '../../../components'

import { NoMatch } from '../../../pages'
import { toast } from 'react-toastify'

import styles from './programs-edit.module.scss'

export function ProgramsEdit() {
  const navigate = useNavigate()
  const { programId } = useParams()

  const formik = useFormik({
    initialValues: {
      name: '',
      product: '',
      description: '',
      resources: '',
      featured_image_url: '',
    },
    validationSchema: UpdateProgram.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const [richTextValue, setRichTextValue] = useState('')
  const [programPackages, setProgramPackages] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [axiosError, setAxiosError] = useState(null)

  const [refreshToggle, setRefreshToggle] = useState(false)

  async function getProgram() {
    await axiosInstance
      .get(`programs/${programId}`)
      .then((res) => {
        const { name, product, description, resources, featured_image_url, programPackages: packages } = res.data
        formik.setFieldValue('name', name)
        formik.setFieldValue('product', product)
        formik.setFieldValue('description', description)
        formik.setFieldValue('resources', resources)
        formik.setFieldValue('featured_image_url', featured_image_url)
        setRichTextValue(resources)
        setProgramPackages(packages)
      })
      .catch((err) => {
        console.log(err)
        setAxiosError(err.response.status)
      })
  }

  useEffect(() => {
    async function getProductTypes() {
      await axiosInstance
        .get('/product-types')
        .then((res) => setProductTypes(res.data.results))
        .catch((error) => console.log(error))
    }
    getProductTypes()
  }, [])

  useEffect(() => {
    getProgram()
  }, [programId, refreshToggle])

  useEffect(() => {
    formik.setFieldValue('resources', richTextValue)
  }, [richTextValue])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`programs/${programId}/update`, values)
      .then(() => {
        toast.success('Program updated successfully!')
        navigate('/admin/programs')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Manage Program</h1>
        <h2>{formik.values['organization_name']}</h2>
        <Tabs>
          <TabList>
            <Tab>Program Details</Tab>
            <Tab>Packages</Tab>
          </TabList>
          <TabPanel>
            <form className="admin_form" onSubmit={formik.handleSubmit}>
              <TextInput
                label="Program Name"
                name="name"
                value={formik.values['name']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasErrors={formik.touched.name && formik.errors.name}
                errorMessage={formik.errors.name}
              />
              <SelectInput
                label="Product Type"
                name="product"
                id="product"
                value={formik.values['product']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasErrors={formik.touched.product && formik.errors.product}
                errorMessage={formik.errors.product}
              >
                {productTypes.map((productType) => (
                  <option key={productType.name} value={productType.name}>
                    {productType.name}
                  </option>
                ))}
              </SelectInput>
              <TextInput
                label="Description"
                name="description"
                value={formik.values['description']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasErrors={formik.touched.description && formik.errors.description}
                errorMessage={formik.errors.description}
              />
              <TextInput
                label="Featured Image URL"
                name="featured_image_url"
                value={formik.values['featured_image_url']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasErrors={formik.touched.featured_image_url && formik.errors.featured_image_url}
                errorMessage={formik.errors.featured_image_url}
              />
              <RichTextInput label="Resources" id="resources" value={richTextValue} onChange={setRichTextValue} />
              <div className="submit_container">
                <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
              </div>
            </form>
          </TabPanel>
          <TabPanel>
            <div className={styles.packages_table}>
              <PackagesTable
                programId={programId}
                programPackages={programPackages}
                setRefreshToggle={setRefreshToggle}
                refreshToggle={refreshToggle}
              />
              <AddPackage programId={programId} setRefreshToggle={setRefreshToggle} refreshToggle={refreshToggle} />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

function PackagesTable({ programId, programPackages, setRefreshToggle, refreshToggle }) {
  async function removePackageFromProgram(packageId) {
    await axiosInstance
      .post(`programs/${programId}/remove-package`, { packageId })
      .then(() => {
        toast.success('Package removed from program successfully.')
        setRefreshToggle((prevToggle) => !prevToggle)
      })
      .catch((err) => toast.error(`Error: ${err}`))
  }

  async function reorderPackage(packageId, direction) {
    await axiosInstance
      .post(`programs/${programId}/reorder`, { packageId, direction })
      .then(() => {
        setRefreshToggle((prevToggle) => !prevToggle)
      })
      .catch((err) => toast.error(`Error: ${err}`))
  }

  const columns = useMemo(
    () => [
      {
        id: 'order',
        header: 'Order',
        accessorKey: 'order',
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        id: 'reorderButton',
        header: 'Move',
        accessorKey: 'id',
        cell: (info) => (
          <div className={styles.reorder_buttons}>
            <button onClick={() => reorderPackage(info.getValue(), 'up')}>🔼</button>
            <button onClick={() => reorderPackage(info.getValue(), 'down')}>🔽</button>
          </div>
        ),
        enableSorting: false,
      },
      {
        id: 'editButton',
        header: 'Edit',
        accessorKey: 'id',
        cell: (info) => <Link href={`/admin/packages/${info.getValue()}`}>Edit</Link>,
        enableSorting: false,
      },
      {
        id: 'removeButton',
        header: 'Remove',
        accessorKey: 'id',
        cell: (info) => (
          <Button onClick={() => removePackageFromProgram(info.getValue())} variant="cancel" icon={<TrashIcon />} />
        ),
        enableSorting: false,
      },
    ],
    []
  )
  return <Table data={programPackages} columns={columns} />
}

function AddPackage({ programId, refreshToggle, setRefreshToggle }) {
  const [showAddTable, setShowAddTable] = useState(false)
  const [packagesToAdd, setPackagesToAdd] = useState([])
  const [searchFilter, setSearchFilter] = useState('')

  async function getAllPackages(searchFilter) {
    await axiosInstance
      .post(`packages/list`, {
        search: searchFilter,
        absentFromProgram: [programId],
      })
      .then((res) => {
        setPackagesToAdd(res.data.results)
      })
  }

  useEffect(() => {
    getAllPackages(searchFilter)
  }, [searchFilter, refreshToggle])

  async function addPackageToProgram(packageId) {
    await axiosInstance
      .post(`programs/${programId}/add-package`, { packageId })
      .then(() => {
        toast.success('Package added to program')
        setShowAddTable(false)
        setRefreshToggle((prevToggle) => !prevToggle)
      })
      .catch((err) => {
        toast.error(`Error: ${err.response.data}`)
      })
  }

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        id: 'addButton',
        header: '',
        accessorKey: 'id',
        cell: (info) => <Button onClick={() => addPackageToProgram(info.getValue())} text="Add" />,
        enableSorting: false,
      },
    ],
    []
  )
  return (
    <>
      {!showAddTable && <Button text="Add a Package" onClick={() => setShowAddTable(true)} />}
      {showAddTable && (
        <Table data={packagesToAdd} columns={columns} searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
      )}
      {showAddTable && <Button text="Cancel" variant="cancel" onClick={() => setShowAddTable(false)} />}
    </>
  )
}
