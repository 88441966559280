import { useState, useEffect } from 'react'
import { useReactTable, getCoreRowModel, flexRender, getSortedRowModel } from '@tanstack/react-table'
import { SelectInput, SortDownIcon, SortUpIcon, SearchIcon } from '../'

import styles from './table.module.scss'

export function Table({
  columns,
  data,
  sorting,
  setSorting,
  searchFilter = null,
  setSearchFilter,
  pagination = null,
  setPagination,
  totalRecords,
  hasDeleteButton = false,
}) {
  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    manualSorting: true,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: pagination !== null ? Math.ceil(totalRecords / pagination.pageSize) : 1,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div>
      {searchFilter !== null && (
        <DebouncedInput
          placeholder="Search..."
          value={searchFilter ?? ''}
          onChange={(value) => setSearchFilter(String(value))}
        />
      )}
      <table className={`${styles.table} ${hasDeleteButton && styles.table_with_delete}`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SortUpIcon />,
                          desc: <SortDownIcon />,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {pagination !== null && (
        <div className={styles.pagination_buttons}>
          <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
            {'<<'}
          </button>
          <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            {'<'}
          </button>
          <span className={styles.page_count}>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {'>'}
          </button>
          <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
            {'>>'}
          </button>
          <SelectInput
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} records
              </option>
            ))}
          </SelectInput>
        </div>
      )}
    </div>
  )
}

function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <div className={styles.search}>
      <SearchIcon />
      <input className={styles.filter_input} {...props} value={value} onChange={(e) => setValue(e.target.value)} />
    </div>
  )
}
