import styles from './button.module.scss'

export function Button({ type, onClick, text, icon, variant, ...props }) {
  let className
  switch (variant) {
    case 'outline':
      className = styles.outline
      break
    case 'secondary':
      className = styles.secondary
      break
    case 'cancel':
      className = styles.cancel
      break
    case 'navLink':
      className = styles.nav_link
      break
    case 'primary':
    default:
      className = styles.primary
      break
  }

  return (
    <button className={className} type={type} onClick={onClick} {...props}>
      {icon} {text}
    </button>
  )
}
