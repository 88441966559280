import { Link } from '../'

import styles from './footer.module.scss'

export function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <div className={styles.footer}>
      <div>© {currentYear} Dialectic Skill Stream. All rights reserved.</div>
      <div>
        Powered by{' '}
        <Link href="https://dialectic.solutions/" variant="footer" newTab={true}>
          Dialectic
        </Link>
      </div>
    </div>
  )
}
