import * as yup_instance from 'yup'
import { ValidationError } from 'yup'
export { ValidationError } from 'yup'

export class ResponseValidationError extends Error {
  constructor({
    message = 'Response entity failed validation.',
    name = 'Response Validation Error',
    innerError = null,
  }) {
    super(message)
    this.name = name
    this.innerError = innerError
  }
}

yup_instance.addMethod(yup_instance.object, 'fieldRequired', function (errorMessage) {
  return this.test(
    'field-required',
    errorMessage ?? 'Object must have at least one recognized field.',
    (obj) => Object.keys(obj).length > 0
  )
})
yup_instance.addMethod(yup_instance.string, 'normalize', function () {
  return this.transform((value) => {
    //Remove all leading and trailing white space
    //and condence consecutive white space characters to a single space
    return value.replace(/\s+/g, ' ').trim()
  })
})
yup_instance.addMethod(yup_instance.string, 'notNullOrWhiteSpace', function () {
  return this.test(
    'no-null-or-whitespace',
    (d) => `${d.path} must have at least one non white space character.`,
    (value) => value.replace(/\s+/g, ' ').trim().length > 0
  )
})
yup_instance.addMethod(yup_instance.Schema, 'validateForOutput', async function (value, options) {
  return this.validate(value, options).catch((e) => {
    if (e instanceof ValidationError) {
      throw new ResponseValidationError({
        message: e.message,
        innerError: e,
      })
    } else {
      throw e
    }
  })
})
yup_instance.addMethod(yup_instance.Schema, 'password', function () {
  return this.test(
    'password',
    (d) => `Password must be at least 14 characters in length.`,
    (value) => value.length >= 14
  )
})

export default yup_instance
