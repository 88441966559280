import yup from '../customYup.js'

/**
 * @typedef {Object} CreateProgram
 * @property {String} name
 * @property {String} product
 * @property {String} description
 * @property {String} resources
 * @property {String} featured_image_url
 */
export class CreateProgram {
  static schema() {
    return createProgramSchema
  }
  static validate(data) {
    return createProgramSchema.validate(data)
  }
}
const createProgramSchema = yup
  .object({
    name: yup.string().required('Program name is required.'),
    product: yup.string().required('Product type is required.'), // TODO: link with item from product_types table
    description: yup.string().default(''),
    resources: yup.string().default(''),
    featured_image_url: yup.string().default(''),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateProgram
 * @property {String} name
 * @property {String} product
 * @property {String} description
 * @property {String} resources
 * @property {String} featured_image_url
 */
export class UpdateProgram {
  static schema() {
    return updateProgramSchema
  }
  static validate(data) {
    return updateProgramSchema.validate(data)
  }
}
const updateProgramSchema = yup
  .object({
    name: yup.string(),
    product: yup.string(), // TODO: link with item from product_types table
    description: yup.string(),
    resources: yup.string(),
    featured_image_url: yup.string(),
  })
  .noUnknown()
  .fieldRequired()

/**
 * @typedef {Object} UpdateProgramRequest
 * @property {UpdateProgram} body new values for program
 * @property {Object} params
 * @property {Number} params.programId program to update
 */
export class UpdateProgramRequest {
  static schema() {
    return updateProgramSchema
  }
  static validate(data) {
    return updateProgramRequestSchema.validate(data)
  }
}
const updateProgramRequestSchema = yup.object({
  body: updateProgramSchema,
  params: yup.object({
    programId: yup.number().required(),
  }),
})

/**
 * The schema for adding or removing a package from a program are both the same
 * @typedef {Object} AddRemovePackage
 * @property {Number} packageId

 */
export class AddRemovePackage {
  static schema() {
    return addRemovePackageSchema
  }
  static validate(data) {
    return addRemovePackageSchema.validate(data)
  }
}
const addRemovePackageSchema = yup
  .object({
    packageId: yup.number().required(),
  })
  .noUnknown()

/**
 * @typedef {Object} AddRemovePackageRequest
 * @property {Object} body
 * @property {Object} params
 * @property {Number} params.programId program to update
 */
export class AddRemovePackageRequest {
  static schema() {
    return addRemovePackageSchema
  }
  static validate(data) {
    return addRemovePackageRequestSchema.validate(data)
  }
}
const addRemovePackageRequestSchema = yup.object({
  body: addRemovePackageSchema,
  params: yup.object({
    programId: yup.number().required(),
  }),
})

/**
 * @typedef {Object} ReorderPackage
 * @property {Number} packageId
 * @property {String} direction

 */
export class ReorderPackage {
  static schema() {
    return reorderPackageSchema
  }
  static validate(data) {
    return reorderPackageSchema.validate(data)
  }
}
const reorderPackageSchema = yup
  .object({
    packageId: yup.number().required(),
    direction: yup.string().oneOf(['up', 'down']),
  })
  .noUnknown()

/**
 * @typedef {Object} ReorderPackageRequest
 * @property {Object} body
 * @property {Object} params
 * @property {Number} params.programId program to update
 */
export class ReorderPackageRequest {
  static schema() {
    return reorderPackageSchema
  }
  static validate(data) {
    return reorderPackageRequestSchema.validate(data)
  }
}
const reorderPackageRequestSchema = yup.object({
  body: reorderPackageSchema,
  params: yup.object({
    programId: yup.number().required(),
  }),
})
