import yup from '../customYup.js'

/**
 * @typedef {Object} CreateUser
 * @property {String} name
 * @property {String} email
 * @property {Number} account_id
 * @property {String} role
 */
export class CreateUser {
  static schema() {
    return createUserSchema
  }
  static validate(data) {
    return createUserSchema.validate(data)
  }
}

const createUserSchema = yup
  .object({
    name: yup.string().required('User name is required.'),
    email: yup.string().email().required('User email is required.'),
    account_id: yup.number().required('Account is required.'),
    role: yup.string().oneOf(['user', 'admin']).required('User role is required.'),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateUser
 * @property {String} name
 * @property {String} email
 * @property {Number} account_id
 * @property {String} role
 * @property {String} password
 */
export class UpdateUser {
  static schema() {
    return updateUserSchema
  }
  static validate(data) {
    return updateUserSchema.validate(data)
  }
}

const updateUserSchema = yup
  .object({
    name: yup.string().required('User name is required.'),
    email: yup.string().email().required('User email is required.'),
    role: yup.string().oneOf(['user', 'admin']).required('User role is required.'),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateUserRequest
 * @property {UpdateUser} body new values for user
 * @property {Object} params
 * @property {Number} params.id user to update
 */
export class UpdateUserRequest {
  static schema() {
    return updateUserSchema
  }
  static validate(data) {
    return updateUserRequestSchema.validate(data)
  }
}
const updateUserRequestSchema = yup.object({
  body: updateUserSchema,
  params: yup.object({
    id: yup.number().required(),
  }),
})

/**
 * @typedef {Object} UserLoginRequest
 * @property {String} email
 * @property {String} password
 */
export class UserLoginRequest {
  static schema() {
    return userLoginRequestSchema
  }
  static validate(data) {
    return userLoginRequestSchema.validate(data)
  }
}
const userLoginRequestSchema = yup.object({
  email: yup.string().email('Invalid email address.').required('Email is required.'),
  password: yup.string().required('Password is required.'),
})

/**
 * @typedef {Object} UserChangePasswordRequest
 * @property {String} currentPassword
 * @property {String} newPassword
 * @property {String} confirmNewPassword
 */
export class UserChangePasswordRequest {
  static schema() {
    return userChangePasswordRequest
  }
  static validate(data) {
    return userChangePasswordRequest.validate(data)
  }
}
const userChangePasswordRequest = yup.object({
  currentPassword: yup.string().password().required('Please enter your current password.'),
  newPassword: yup.string().password().required('Please enter your new password.'),
  confirmNewPassword: yup
    .string()
    .password()
    .required('Please re-type your password.')
    .oneOf([yup.ref('newPassword')], 'Confirmation password must match.'),
})

/**
 * @typedef {Object} UserResetPasswordRequest
 * @property {String} resetPasswordToken
 * @property {Number} userId
 * @property {String} newPassword
 * @property {String} confirmNewPassword
 */
export class UserResetPasswordRequest {
  static schema() {
    return userResetPasswordRequest
  }
  static validate(data) {
    return userResetPasswordRequest.validate(data)
  }
}
const userResetPasswordRequest = yup.object({
  resetPasswordToken: yup.string().required(),
  userId: yup.number().required(),
  newPassword: yup.string().password().required('Please enter your new password.'),
  confirmNewPassword: yup
    .string()
    .password()
    .required('Please re-type your password.')
    .oneOf([yup.ref('newPassword')], 'Confirmation password must match.'),
})

/**
 * @typedef {Object} UserResetPasswordLinkRequest
 * @property {String} email
 */
export class UserResetPasswordLinkRequest {
  static schema() {
    return userResetPasswordLinkRequest
  }
  static validate(data) {
    return userResetPasswordLinkRequest.validate(data)
  }
}
const userResetPasswordLinkRequest = yup.object({
  email: yup.string().email().required(),
})

/**
 * @typedef {Object} UserAcceptTOSRequest
 * @property {Boolean} acceptTermsOfService
 */
export class UserAcceptTOSRequest {
  static schema() {
    return userAcceptTOSRequestSchema
  }
  static validate(data) {
    return userAcceptTOSRequestSchema.validate(data)
  }
}
const userAcceptTOSRequestSchema = yup.object({
  acceptTermsOfService: yup
    .boolean()
    .required()
    .equals([true], 'Please accept the terms of service before proceeding.'),
})
