import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../axiosInstance.js'
import { useAuth } from '../../hooks/useAuth.js'
import { Link, Button } from '../'

import styles from './navbar.module.scss'
import DSSIcon from '../../shared/images/DSS-icon-white@4x.png'

function UserNavLinks() {
  return (
    <>
      <Link href="/account" variant="navLink">
        Account
      </Link>
      <Link href="/profile" variant="navLink">
        Profile
      </Link>
    </>
  )
}

function AdminNavLinks() {
  return (
    <>
      <Link href="accounts" variant="navLink">
        Accounts
      </Link>
      <Link href="users" variant="navLink">
        Users
      </Link>
      <Link href="programs" variant="navLink">
        Programs
      </Link>
      <Link href="packages" variant="navLink">
        Packages
      </Link>
      <Link href="product-types" variant="navLink">
        Product Types
      </Link>
      <Link href="profile" variant="navLink">
        Profile
      </Link>
    </>
  )
}

export function Navbar() {
  const { isAdmin } = useAuth()
  const navigate = useNavigate()

  async function logout() {
    await axiosInstance
      .post('logout')
      .then(() => navigate('/login'))
      .catch((err) => console.log(`Error: ${err.response.data}`))
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.site_name}>
        <Link href="/" variant="navLink">
          <img src={DSSIcon} alt="Dialectic Skill Stream Home" />
        </Link>
      </div>
      <div className={styles.links}>
        {isAdmin ? <AdminNavLinks /> : <UserNavLinks />}
        <Button text="Log Out" variant="navLink" onClick={logout} />
      </div>
    </div>
  )
}
