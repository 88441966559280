import { useEffect, useState, useMemo } from 'react'
import DOMPurify from 'dompurify'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SCORM_12, SCORM_2004, xAPI, ENGLISH, FRENCH } from 'common/constants'
import {
  Button,
  Downloading,
  Loading,
  Sidebar,
  Chip,
  SelectInput,
  Table,
  CalendarIcon,
  SeatsIcon,
  DownloadIcon,
} from '../../components'
import { NoMatch } from '../'
import { axiosInstance } from '../../axiosInstance'
import placeholderImage from '../../shared/images/login-logo.png'

import styles from './program.module.scss'

export function Program() {
  const { programId } = useParams()

  const [programData, setProgramData] = useState({})
  const [loading, setLoading] = useState(true)
  const [downloading, setDownloading] = useState(false)
  const [axiosError, setAxiosError] = useState(null)

  const [availableFormats, setAvailableFormats] = useState([])
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [scormFormat, setScormFormat] = useState('')
  const [language, setLanguage] = useState('')
  const [selectedPackages, setSelectedPackages] = useState([])
  const [isSubscribed, setIsSubscribed] = useState(false)

  async function getProgramData() {
    await axiosInstance
      .get(`programs/client-program/${programId}`)
      .then((res) => {
        if (res.data.programWithPackages === null) {
          setAxiosError(404)
        } else {
          setProgramData(res.data)

          const formats = getAvailableFormats(res.data.programWithPackages.programPackages)
          setAvailableFormats(formats)
          setScormFormat(formats[0])

          const languages = getAvailableLanguages(res.data.programWithPackages.programPackages, formats[0])
          setAvailableLanguages(languages)
          setLanguage(languages[0])

          if (new Date(res.data.userSubscription?.ends_at).getTime() > new Date().getTime()) {
            setIsSubscribed(true)
          } else {
            setIsSubscribed(false)
          }
        }
      })
      .catch((err) => {
        setAxiosError(err.response?.status)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getProgramData()
  }, [programId])

  useEffect(() => {
    const initialSelected = programData?.programWithPackages?.programPackages.map(
      (programPackages) => programPackages.id
    )
    setSelectedPackages(() => initialSelected)
  }, [programData])

  useEffect(() => {
    if (programData?.programWithPackages?.programPackages) {
      const languages = getAvailableLanguages(programData?.programWithPackages?.programPackages, scormFormat)
      setAvailableLanguages(languages)
      setLanguage(languages[0])
    }
  }, [scormFormat])

  const { programWithPackages, userSubscription } = programData

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(programWithPackages?.resources, { ADD_ATTR: ['target'] }),
  })

  function putInAscendingOrder(a, b) {
    return a - b
  }

  function handleSelectChange(e) {
    const selectedPackageId = Number(e.target.value)
    if (e.target.checked) {
      setSelectedPackages((prevPackages) => [...prevPackages, selectedPackageId].sort(putInAscendingOrder))
    } else {
      setSelectedPackages((prevPackages) =>
        prevPackages.filter((packageId) => packageId !== selectedPackageId).sort(putInAscendingOrder)
      )
    }
  }

  async function handleDownload() {
    setDownloading(true)
    await axiosInstance
      .post(
        `package-files/download-packages`,
        {
          packageIds: selectedPackages,
          scormFormat,
          language,
        },
        { responseType: 'blob' }
      )
      .then((res) => {
        downloadProgram(res.data, programWithPackages.name)
      })
      .catch(async (error) => {
        // the response type was set to blob (above) and needs to be transformed to text to display the error
        const data = await new Response(error.response.data).arrayBuffer()
        const decoder = new TextDecoder('utf-8')
        const text = decoder.decode(data)
        toast.error(text)
      })
      .finally(() => setDownloading(false))
  }

  if (loading) return <Loading />

  if (downloading) return <Downloading />

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div className={styles.program_page}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{programWithPackages.name}</h1>
          <Chip>{programWithPackages.product}</Chip>
        </div>
        <div className={styles.program_info}>
          <div className={styles.left_sidebar}>
            <img src={programWithPackages?.featured_image_url || placeholderImage} alt="Program logo" />
          </div>
          <section>
            <div>{programWithPackages.description}</div>
            <div className={styles.details}>
              {isSubscribed ? (
                <>
                  <div className={styles.container}>
                    <div className={styles.icon_group}>
                      <SeatsIcon />
                      <span>Seats: {userSubscription?.seats}</span>
                    </div>
                    <div className={styles.icon_group}>
                      <CalendarIcon />
                      <span>
                        Exp.{' '}
                        {new Date(userSubscription?.ends_at).toLocaleDateString('en-CA', { dateStyle: 'long' }) ??
                          'N/A'}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    <strong>*You are not subscribed for this program.</strong>
                  </p>
                </>
              )}
            </div>
          </section>
        </div>
        <section className={styles.download_section}>
          <div className={styles.select_dropdowns}>
            <SelectInput label="Version" value={scormFormat} onChange={(e) => setScormFormat(e.target.value)}>
              {availableFormats &&
                availableFormats.map((format) => (
                  <option key={format} value={format}>
                    {format}
                  </option>
                ))}
            </SelectInput>
            <SelectInput label="Language" value={language} onChange={(e) => setLanguage(e.target.value)}>
              {availableLanguages &&
                availableLanguages.map((language) => (
                  <option key={language} value={language}>
                    {language === ENGLISH ? 'English' : 'French'}
                  </option>
                ))}
            </SelectInput>
          </div>
          {isSubscribed ? (
            <PackagesTable
              programPackages={programWithPackages.programPackages}
              handleSelectChange={handleSelectChange}
            />
          ) : (
            <NoSubscriptionPackagesTable programPackages={programWithPackages.programPackages} />
          )}
        </section>
        <div className={styles.download_button}>
          {isSubscribed && (
            <Button
              text="Download"
              icon={<DownloadIcon />}
              variant="secondary"
              onClick={handleDownload}
              disabled={downloading}
            />
          )}
        </div>
      </div>
      <Sidebar>
        <h3>Program Resources</h3>
        <div className="richtext" dangerouslySetInnerHTML={sanitizedData()} />
      </Sidebar>
    </div>
  )
}

function PackagesTable({ programPackages, handleSelectChange }) {
  const columns = useMemo(
    () => [
      {
        id: 'order',
        header: 'Order',
        accessorKey: 'order',
        enableSorting: false,
      },
      {
        id: 'name',
        header: 'Content',
        accessorKey: 'name',
        enableSorting: false,
      },
      {
        id: 'selectCheckbox',
        header: '',
        accessorKey: 'id',
        cell: (info) => <input type="checkbox" defaultChecked value={info.getValue()} onChange={handleSelectChange} />,
        enableSorting: false,
      },
    ],
    []
  )
  return <Table data={programPackages} columns={columns} />
}

function NoSubscriptionPackagesTable({ programPackages }) {
  const columns = useMemo(
    () => [
      {
        id: 'order',
        header: 'Order',
        accessorKey: 'order',
        enableSorting: false,
      },
      {
        id: 'name',
        header: 'Content',
        accessorKey: 'name',
        enableSorting: false,
      },
      {
        id: 'selectCheckbox',
        header: '',
        accessorKey: 'id',
        cell: <input type="checkbox" defaultChecked disabled />,
        enableSorting: false,
      },
    ],
    []
  )
  return <Table data={programPackages} columns={columns} />
}

function downloadProgram(fileData, programName) {
  const url = URL.createObjectURL(fileData)
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = programName
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
  URL.revokeObjectURL(url)
}

// If every package has at least one file in the specified format, return true
function formatIsAvailable(format, packagesArray) {
  return packagesArray.every((_package) => _package.files.some((file) => file.format === format))
}

function getAvailableFormats(packagesArray) {
  const formats = [SCORM_12, SCORM_2004, xAPI]
  const availableFormatsForProgram = []

  formats.forEach((format) => {
    if (formatIsAvailable(format, packagesArray)) {
      availableFormatsForProgram.push(format)
    }
  })

  return availableFormatsForProgram
}

// If every package has at least one file in the specified language and format, return true
function languageIsAvailable(language, format, packagesArray) {
  return packagesArray.every((_package) =>
    _package.files.some((file) => file.format === format && file.language === language)
  )
}

function getAvailableLanguages(packagesArray, selectedFormat) {
  const languages = [ENGLISH, FRENCH]
  const availableLanguagesForProgram = []

  languages.forEach((language) => {
    if (languageIsAvailable(language, selectedFormat, packagesArray)) {
      availableLanguagesForProgram.push(language)
    }
  })

  return availableLanguagesForProgram
}
