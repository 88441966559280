import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { UpdateProductType } from 'common/schema/*'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput } from '../../../components'
import { NoMatch } from '../../../pages'

export function ProductTypesEdit() {
  const navigate = useNavigate()
  const { productTypeName } = useParams()

  const [axiosError, setAxiosError] = useState(null)

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: UpdateProductType.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  useEffect(() => {
    async function getProductType() {
      await axiosInstance
        .get(`product-types/${productTypeName}`)
        .then((res) => {
          formik.setFieldValue('name', res.data.name)
        })
        .catch((err) => {
          console.log(err)
          setAxiosError(err.response.status)
        })
    }
    getProductType()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`product-types/${productTypeName}/update`, values)
      .then(() => {
        toast.success('Product Type updated successfully!')
        navigate('/admin/product-types')
      })
      .catch((err) => toast.error(`Error: ${err}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Edit Product Type</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <TextInput
            label="Name"
            name="name"
            value={formik.values['name']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
