import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faSortUp,
  faSortDown,
  faSearch,
  faUser,
  faDownload,
  faCheck,
  faEnvelope,
  faTrash,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'

export function CalendarIcon() {
  return <FontAwesomeIcon icon={faCalendarDays} style={{ color: '#404040' }} size="lg" />
}

export function SeatsIcon() {
  return <FontAwesomeIcon icon={faUser} style={{ color: '#404040' }} size="lg" />
}

export function SortUpIcon() {
  return <FontAwesomeIcon icon={faSortUp} size="lg" />
}

export function SortDownIcon() {
  return <FontAwesomeIcon icon={faSortDown} size="lg" />
}

export function DownloadIcon() {
  return <FontAwesomeIcon icon={faDownload} size="lg" />
}

export function CheckMarkIcon() {
  return (
    <FontAwesomeIcon
      icon={faCheck}
      style={{ color: '#00780F', border: '1px solid #404040', borderRadius: '5px', padding: '10px' }}
    />
  )
}

export function EmailIcon() {
  return <FontAwesomeIcon icon={faEnvelope} />
}

export function SearchIcon() {
  return <FontAwesomeIcon icon={faSearch} />
}

export function TrashIcon() {
  return <FontAwesomeIcon icon={faTrash} />
}

export function EditIcon() {
  return <FontAwesomeIcon icon={faPenToSquare} />
}
