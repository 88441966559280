import { useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { ENGLISH } from 'common/constants'

import { axiosInstance } from '../../../../axiosInstance'
import { Button, FileInput } from '../../../../components'
import { FileListing } from './file-listing'

import styles from '../packages-edit.module.scss'

export function ScormPackage({ packageId, packageFile, format, language, refreshToggle, setRefreshToggle }) {
  const [showUploadInput, setShowUploadInput] = useState(false)

  const formik = useFormik({
    initialValues: {
      scormFile: null,
    },
    onSubmit: (values) => (packageFile ? handleUpdate(values) : handleCreate(values)),
  })

  function handleChange(event) {
    formik.setFieldValue('scormFile', event.target.files[0])
  }

  async function handleCreate(values) {
    await axiosInstance
      .post(`package-files/${packageId}/create`, values, {
        params: { format, language },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        toast.success('Package file uploaded successfully!')
        setRefreshToggle(!refreshToggle)
        setShowUploadInput(false)
      })
      .catch((err) => console.log(`Error: ${err}`))
  }

  async function handleUpdate(values) {
    await axiosInstance
      .post(`package-files/${packageId}/${packageFile.id}/update`, values, {
        params: { format, language },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        toast.success('Package file updated successfully!')
        setRefreshToggle(!refreshToggle)
        setShowUploadInput(false)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <form className="admin_form" onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <h3 className={styles.language_header}>{language === ENGLISH ? 'English' : 'French'}</h3>
      {packageFile && <FileListing file={packageFile} />}
      {showUploadInput || !packageFile ? (
        <>
          <FileInput label="Upload File" onChange={handleChange} />
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
            <Button text="Cancel" variant="cancel" onClick={() => setShowUploadInput(false)} />
          </div>
        </>
      ) : (
        <div className="submit_container">
          <Button text="Replace File" variant="primary" onClick={() => setShowUploadInput(true)} />
        </div>
      )}
    </form>
  )
}
