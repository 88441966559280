import { Link, Chip, SeatsIcon, CalendarIcon } from '../'
import placeholderImage from '../../shared/images/login-logo.png'

import styles from './program-card.module.scss'

// for user's subscribed programs
export function AccountProgramCard({ programId, name, description, product, featuredImageUrl, endsAt, seats }) {
  return (
    <div className={styles.program_card}>
      <img src={featuredImageUrl || placeholderImage} alt="Program logo" />
      <div className={styles.info}>
        <div className={styles.header}>
          <h3>{name}</h3>
          <Chip>{product}</Chip>
        </div>
        <div className={styles.description}>{description}</div>
        <div className={styles.details}>
          <div className={styles.container}>
            <div className={styles.icon_group}>
              <SeatsIcon />
              <span>Seats: {seats}</span>
            </div>
            <div className={styles.icon_group}>
              <CalendarIcon />
              <span>Exp. {new Date(endsAt).toLocaleDateString('en-CA', { dateStyle: 'long' })}</span>
            </div>
          </div>
          <Link href={`/program/${programId}`} variant="primary">
            View Program
          </Link>
        </div>
      </div>
    </div>
  )
}

// programs for which the user is not subscribed
export function ProgramCard({ programId, name, description, product, featuredImageUrl }) {
  return (
    <div className={styles.program_card}>
      <img src={placeholderImage} alt="Program logo" />
      <div className={styles.info}>
        <div className={styles.header}>
          <h3>{name}</h3>
          <Chip>{product}</Chip>
        </div>
        <div>{description}</div>
        <div className={styles.details}>
          <Link href={`/program/${programId}`} variant="primary">
            View Program
          </Link>
        </div>
      </div>
    </div>
  )
}
