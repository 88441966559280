import { useEffect, useState, useMemo } from 'react'
import { axiosInstance } from '../../../axiosInstance'

import { EditIcon, Table, Link, Loading } from '../../../components'

export function ProgramsList() {
  const [programsData, setProgramsData] = useState([])
  const [sorting, setSorting] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [totalRecords, setTotalRecords] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(true)

  async function programsList(sorting, searchFilter, pagination) {
    const { id: sortingColumn = '', desc = false } = sorting[0] || []
    const sortingOrder = desc ? 'desc' : 'asc'
    await axiosInstance
      .post('programs/list', {
        skip: pagination.pageIndex * pagination.pageSize,
        take: pagination.pageSize,
        sort: [{ column: sortingColumn || 'programs.id', order: sortingOrder }],
        search: searchFilter,
      })
      .then((res) => {
        setProgramsData(res.data.results)
        setTotalRecords(res.data.resultCount)
      })
      .catch((err) => console.log(`Error: ${err.response.data}`))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    programsList(sorting, searchFilter, pagination)
  }, [sorting, searchFilter, pagination])

  useEffect(() => {
    setPagination({
      ...pagination,
      pageIndex: 0,
    })
  }, [sorting, searchFilter])

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'name',
        header: 'Program Name',
        accessorKey: 'name',
      },
      {
        id: 'product',
        header: 'Product Type',
        accessorKey: 'product',
      },
      {
        id: 'Edit',
        accessorKey: 'id',
        header: 'Edit',
        cell: (info) => (
          <Link href={`${info.getValue()}`}>
            <EditIcon />
          </Link>
        ),
        enableSorting: false,
      },
    ],
    []
  )

  if (loading) return <Loading />

  return (
    <div>
      <div className="admin_list_page_header">
        <h1>Manage Programs</h1>
        <Link href="create">Add Program</Link>
      </div>
      <Table
        data={programsData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        pagination={pagination}
        setPagination={setPagination}
        totalRecords={totalRecords}
      />
    </div>
  )
}
