import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import { axiosInstance } from '../../../axiosInstance'
import { Button, ReadOnlyInput } from '../../../components'

import { NoMatch } from '../../../pages'
import { toast } from 'react-toastify'

export function AccountsDelete() {
  const navigate = useNavigate()
  const { accountId } = useParams()

  const formik = useFormik({
    initialValues: {
      organization_name: '',
    },
    onSubmit: (values) => handleSubmit(values),
  })

  const [isArchived, setIsArchived] = useState(false)
  const [axiosError, setAxiosError] = useState(null)

  useEffect(() => {
    async function getAccount() {
      await axiosInstance
        .get(`accounts/${accountId}`)
        .then((res) => {
          const { organization_name, deleted_at } = res.data
          formik.setFieldValue('organization_name', organization_name)
          if (deleted_at) {
            setIsArchived(true)
          }
        })
        .catch((err) => {
          console.log(err)
          setAxiosError(err.response.status)
        })
    }
    getAccount()
  }, [accountId])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`accounts/${accountId}/delete`, values)
      .then(() => {
        toast.success('Account deleted successfully!')
        navigate('/admin/accounts')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Remove Account</h1>
        <h2>{formik.values['organization_name']}</h2>
        {isArchived ? (
          <p>
            <b>*This is an archived account</b>
          </p>
        ) : (
          <p>
            <b>*Please note</b> that upon removal of this account, all its account users will be deleted.
            <br /> Any active account subscriptions will remain active unless manually expired by the admin.
          </p>
        )}
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <ReadOnlyInput label="Organization Name" value={formik.values['organization_name']} />
          <div className="submit_container">
            <Button
              type="submit"
              text="Confirm Delete"
              variant="primary"
              disabled={formik.isSubmitting || isArchived}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
