import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

export function useAuth() {
  // Get the browser cookie called access_token
  let token = Cookies.get('access_token')
  let auth = null
  // if the cookie exists, decode it to get the user information
  if (token) {
    token = token.split(' ')[1] // Remove Bearer from token string
    auth = jwtDecode(token)
  }

  // set variables for isLoggedIn and isAdmin
  const isLoggedIn = auth ? true : false
  const isAdmin = auth?.role === 'admin'
  const hasAcceptedTOS = auth?.accepted_tos_at ? true : false

  // return variables
  return { isLoggedIn, isAdmin, hasAcceptedTOS }
}
