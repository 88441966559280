import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UpdateSubscription } from 'common/schema/*'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, NumberInput, DateInput, ReadOnlyInput } from '../../../components'

export function SubscriptionsEdit() {
  const navigate = useNavigate()
  const { accountId, subscriptionId } = useParams()

  const formik = useFormik({
    initialValues: {
      program_name: '',
      startsAt: '',
      endsAt: '',
      seats: '',
    },
    validationSchema: UpdateSubscription.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  async function getSubscription() {
    await axiosInstance
      .get(`subscriptions/${subscriptionId}`)
      .then((res) => {
        formik.setFieldValue('program_name', res.data.program_name)
        formik.setFieldValue('startsAt', new Date(res.data.starts_at).toLocaleDateString('en-CA'))
        formik.setFieldValue('endsAt', new Date(res.data.ends_at).toLocaleDateString('en-CA'))
        formik.setFieldValue('seats', res.data.seats)
      })
      .catch((err) => console.log(`Error: ${err.response.data}`))
  }

  useEffect(() => {
    getSubscription()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`subscriptions/${subscriptionId}/update`, values)
      .then(() => {
        toast.success('Subscription updated successfully!')
        navigate(`/admin/accounts/${accountId}`)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Edit Subscription</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <ReadOnlyInput label="Program" value={formik.values['program_name']} />
          <NumberInput
            label="Seats"
            name="seats"
            value={formik.values['seats']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.seats && formik.errors.seats}
            errorMessage={formik.errors.seats}
          />
          <DateInput
            label="Start Date"
            name="startsAt"
            value={formik.values['startsAt']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.startsAt && formik.errors.startsAt}
            errorMessage={formik.errors.startsAt}
          />
          <DateInput
            label="Expiry Date"
            name="endsAt"
            value={formik.values['endsAt']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.endsAt && formik.errors.endsAt}
            errorMessage={formik.errors.endsAt}
          />
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
