import { Link as ReactRouterLink } from 'react-router-dom'
import styles from './link.module.scss'

export function Link({ href, variant, children, newTab = false, ...props }) {
  let className
  switch (variant) {
    case 'outline':
      className = styles.outline
      break
    case 'secondary':
      className = styles.secondary
      break
    case 'navLink':
      className = styles.nav_link
      break
    case 'textLink':
      className = styles.text_link
      break
    case 'footer':
      className = styles.footer_link
      break
    case 'primary':
    default:
      className = styles.primary
      break
  }

  return (
    <ReactRouterLink className={className} to={href} target={newTab ? '_blank' : '_self'} {...props}>
      {children}
    </ReactRouterLink>
  )
}
