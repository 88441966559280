import { useEffect } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import {
  Login,
  Profile,
  Program,
  Account,
  NoMatch,
  TermsOfService,
  UserActivation,
  ResetPassword,
  RequestResetPassword,
} from './pages'
import {
  AccountsCreate,
  AccountsEdit,
  AccountsList,
  AccountsDelete,
  UsersList,
  UsersCreate,
  UsersEdit,
  UsersDelete,
  PackagesCreate,
  PackagesList,
  PackagesEdit,
  ProgramsList,
  ProgramsCreate,
  ProgramsEdit,
  SubscriptionsCreate,
  SubscriptionsEdit,
  ProductTypesList,
  ProductTypesCreate,
  ProductTypesEdit,
} from './admin'
import { Navbar, Footer } from './components'
import { useAuth } from './hooks/useAuth'

import './App.global.scss'

function Main({ children }) {
  return (
    <main id="main-content" className="main">
      {children}
    </main>
  )
}

function Public() {
  return <Outlet />
}

function AuthenticatedNotActivated() {
  const navigate = useNavigate()
  const { isLoggedIn, hasAcceptedTOS } = useAuth()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    } else if (!hasAcceptedTOS) {
      navigate('/terms-of-service')
    }
  }, [isLoggedIn, hasAcceptedTOS, navigate])

  if (!isLoggedIn) return <NoMatch errorStatus="401" errorStatusText="You are not logged in." />

  return (
    <>
      <Main>
        <Outlet />
      </Main>
    </>
  )
}

function Authenticated() {
  const navigate = useNavigate()
  const { isLoggedIn, isAdmin, hasAcceptedTOS } = useAuth()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    } else if (!hasAcceptedTOS) {
      navigate('/terms-of-service')
    } else if (isAdmin) {
      navigate('/admin')
    }
  }, [isLoggedIn, isAdmin, hasAcceptedTOS, navigate])

  if (!isLoggedIn) return <NoMatch errorStatus="401" errorStatusText="You are not logged in." />
  if (!hasAcceptedTOS)
    return <NoMatch errorStatus="401" errorStatusText="You have not yet accepted the Terms of Service." />

  return (
    <>
      <Navbar />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  )
}

function AuthenticatedAdmin() {
  const navigate = useNavigate()
  const { isLoggedIn, isAdmin, hasAcceptedTOS } = useAuth()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    } else if (!hasAcceptedTOS) {
      navigate('/terms-of-service')
    }
  }, [isLoggedIn, hasAcceptedTOS, navigate])

  if (!isLoggedIn) return <NoMatch errorStatus="401" errorStatusText="You are not logged in." />
  if (!isAdmin) return <NoMatch errorStatus="403" errorStatusText="You are not authorized to view this page." />

  return (
    <>
      <Navbar />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  )
}

const router = createBrowserRouter([
  {
    element: <Authenticated />,
    errorElement: <NoMatch />,
    children: [
      { index: true, element: <Navigate to="/account" /> },
      { path: '/account', element: <Account /> },
      { path: '/program/:programId', element: <Program /> },
      { path: '/profile', element: <Profile /> },
    ],
  },
  {
    path: '/admin',
    element: <AuthenticatedAdmin />,
    errorElement: <NoMatch />,
    children: [
      { index: true, element: <Navigate to="accounts" /> },
      {
        path: 'accounts',
        element: <Outlet />,
        children: [
          { index: true, element: <AccountsList /> },
          { path: 'create', element: <AccountsCreate /> },
          { path: ':accountId/delete', element: <AccountsDelete /> },
          {
            path: ':accountId',
            exact: true,
            element: <Outlet />,
            children: [
              { index: true, element: <AccountsEdit /> },
              { path: 'subscriptions/create', element: <SubscriptionsCreate /> },
              { path: 'subscriptions/:subscriptionId/edit', element: <SubscriptionsEdit /> },
            ],
          },
        ],
      },
      {
        path: 'users',
        element: <Outlet />,
        children: [
          { index: true, element: <UsersList /> },
          { path: 'create', element: <UsersCreate /> },
          { path: ':userId/edit', element: <UsersEdit /> },
          { path: ':userId/delete', element: <UsersDelete /> },
        ],
      },
      {
        path: 'programs',
        element: <Outlet />,
        children: [
          { index: true, element: <ProgramsList /> },
          { path: 'create', element: <ProgramsCreate /> },
          { path: ':programId', element: <ProgramsEdit /> },
        ],
      },
      {
        path: 'packages',
        element: <Outlet />,
        children: [
          { index: true, element: <PackagesList /> },
          { path: 'create', element: <PackagesCreate /> },
          { path: ':packageId', element: <PackagesEdit /> },
        ],
      },
      {
        path: 'product-types',
        element: <Outlet />,
        children: [
          { index: true, element: <ProductTypesList /> },
          { path: 'create', element: <ProductTypesCreate /> },
          { path: ':productTypeName', element: <ProductTypesEdit /> },
        ],
      },
      { path: 'profile', element: <Profile /> },
    ],
  },
  {
    element: <Public />,
    errorElement: <NoMatch />,
    children: [
      { index: true, element: <Navigate to="/login" /> },
      { path: '/login', element: <Login /> },
      { path: '/activate/:userId/:resetPasswordToken', element: <UserActivation /> },
      { path: '/reset-password/:userId/:resetPasswordToken', element: <ResetPassword /> },
      { path: '/request-reset-password', element: <RequestResetPassword /> },
    ],
  },
  {
    element: <AuthenticatedNotActivated />,
    errorElement: <NoMatch />,
    children: [
      { index: true, element: <Navigate to="/terms-of-service" /> },
      { path: '/terms-of-service', element: <TermsOfService /> },
    ],
  },
])

export function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export default App
