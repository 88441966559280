import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { axiosInstance } from '../../axiosInstance'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { UserLoginRequest } from 'common/schema/*'

import { Button, Link, TextInput, PasswordInput } from '../../components'

import styles from './login.module.scss'
import siteLogo from '../../shared/images/DSS-logo-orange@4x.png'

export function Login() {
  const navigate = useNavigate()
  const [welcomeText, setWelcomeText] = useState('')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: UserLoginRequest.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  async function getWelcomeText() {
    await axiosInstance.get('welcome-text').then((res) => {
      setWelcomeText(res.data)
    })
  }

  useEffect(() => {
    getWelcomeText()
  }, [])

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(welcomeText, { ADD_ATTR: ['target'] }),
  })

  async function handleSubmit(values) {
    await axiosInstance
      .post('login', values)
      .then((res) => {
        const userRole = res.data.role
        if (userRole === 'admin') {
          navigate('/admin')
        } else {
          navigate('/')
        }
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div className={styles.login}>
      <div className={styles.product_info}>
        <div className={styles.container}>
          <img src={siteLogo} alt="Dialectic Skill Stream Logo" />
          <div className="richtext" dangerouslySetInnerHTML={sanitizedData()} />
        </div>
      </div>
      <div className={styles.login_form}>
        <form onSubmit={formik.handleSubmit}>
          <h3>Sign In</h3>

          <TextInput
            label="Email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.email && formik.errors.email}
            errorMessage={formik.errors.email}
          />

          <PasswordInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.password && formik.errors.password}
            errorMessage={formik.errors.password}
          />

          <div className={styles.submit_container}>
            <Button type="submit" text="Log in" variant="primary" />
            <Link href="/request-reset-password" variant="textLink">
              Forgot Password
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}
