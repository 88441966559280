import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { CreateProductType } from 'common/schema/*'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput } from '../../../components'

import { toast } from 'react-toastify'

export function ProductTypesCreate() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: CreateProductType.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  async function handleSubmit(values) {
    await axiosInstance
      .post('product-types/create', values)
      .then(() => {
        toast.success('Product Type created successfully!')
        navigate('/admin/product-types')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Add Product Type</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <TextInput
            label="Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
