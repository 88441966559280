import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { CreateProgram } from 'common/schema/*'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput, RichTextInput, SelectInput } from '../../../components'

import { toast } from 'react-toastify'

export function ProgramsCreate() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
      product: '',
      description: '',
      resources: '',
      featured_image_url: '',
    },
    validationSchema: CreateProgram.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const [productTypes, setProductTypes] = useState([])
  const [richTextValue, setRichTextValue] = useState('')

  useEffect(() => {
    async function getProductTypes() {
      await axiosInstance
        .get('/product-types')
        .then((res) => setProductTypes(res.data.results))
        .catch((error) => console.log(error))
    }
    getProductTypes()
  }, [])

  useEffect(() => {
    formik.setFieldValue('resources', richTextValue)
  }, [richTextValue])

  async function handleSubmit(values) {
    await axiosInstance
      .post('programs/create', values)
      .then(() => {
        toast.success('Program created successfully!')
        navigate('/admin/programs')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Add Program</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <TextInput
            label="Program Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <SelectInput
            label="Product Type"
            name="product"
            id="product"
            value={formik.values['product'] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.product && formik.errors.product}
            errorMessage={formik.errors.product}
          >
            {productTypes.map((productType) => (
              <option key={productType.name} value={productType.name}>
                {productType.name}
              </option>
            ))}
          </SelectInput>
          <TextInput
            label="Description"
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.description && formik.errors.description}
            errorMessage={formik.errors.description}
          />
          <TextInput
            label="Featured Image URL"
            name="featured_image_url"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.featured_image_url && formik.errors.featured_image_url}
            errorMessage={formik.errors.featured_image_url}
          />
          <RichTextInput label="Resources" id="resources" value={richTextValue} onChange={setRichTextValue} />
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
