import { useEffect, useState, useMemo } from 'react'
import { axiosInstance } from '../../../axiosInstance'

import { Table, Link, Loading, TrashIcon, EditIcon } from '../../../components'

export function UsersList() {
  const [usersData, setUsersData] = useState([])
  const [sorting, setSorting] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [totalRecords, setTotalRecords] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(true)

  async function getUsers(sorting, searchFilter, pagination) {
    if (sorting.length === 0) {
      sorting = [{ id: 'users.id', desc: false }]
    }
    await axiosInstance
      .get('users', { params: { search: searchFilter, sorting, pagination } })
      .then((res) => {
        setUsersData(res.data.users)
        setTotalRecords(res.data.userCount)
      })
      .catch((err) => console.log(`Error: ${err.response.data}`))
      .finally(() => setLoading(false))
  }

  async function usersList(sorting, searchFilter, pagination) {
    const { id: sortingColumn = '', desc = false } = sorting[0] || []
    const sortingOrder = desc ? 'desc' : 'asc'
    await axiosInstance
      .post('users/list', {
        skip: pagination.pageIndex * pagination.pageSize,
        take: pagination.pageSize,
        sort: [{ column: sortingColumn || 'users.id', order: sortingOrder }],
        search: searchFilter,
      })
      .then((res) => {
        setUsersData(res.data.results)
        setTotalRecords(res.data.resultCount)
      })
      .catch((err) => console.log(`Error: ${err.response.data}`))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    usersList(sorting, searchFilter, pagination)
  }, [sorting, searchFilter, pagination])

  useEffect(() => {
    setPagination({
      ...pagination,
      pageIndex: 0,
    })
  }, [sorting, searchFilter])

  const columns = useMemo(
    () => [
      {
        id: 'users.id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'users.name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        id: 'accounts.organization_name',
        header: 'Account',
        accessorKey: 'organization_name',
      },
      {
        id: 'users.email',
        header: 'Email',
        accessorKey: 'email',
      },
      {
        id: 'users.role',
        header: 'Role',
        accessorKey: 'role',
      },
      {
        id: 'editButton',
        header: 'Edit',
        accessorKey: 'id',
        cell: (info) => (
          <Link href={`${info.getValue()}/edit`}>
            <EditIcon />
          </Link>
        ),
        enableSorting: false,
      },
      {
        id: 'deleteButton',
        header: 'Delete',
        accessorKey: 'id',
        cell: (info) => (
          <Link href={`${info.getValue()}/delete`}>
            <TrashIcon />
          </Link>
        ),
        enableSorting: false,
      },
    ],
    []
  )

  if (loading) return <Loading />

  return (
    <div>
      <div className="admin_list_page_header">
        <h1>Manage Users</h1>
        <Link href="create">Add New User</Link>
      </div>
      <Table
        data={usersData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        pagination={pagination}
        setPagination={setPagination}
        totalRecords={totalRecords}
        hasDeleteButton={true}
      />
    </div>
  )
}
