import yup from '../customYup.js'
//ReExport PackageListOptions so that
//it is available from Package and ListOptions
export { PackageListOptions } from './listOptions.schema.js'

/**
 * @typedef {Object} CreatePackage
 * @property {String} name
 */
export class CreatePackage {
  static schema() {
    return createPackageSchema
  }
  static validate(data) {
    return createPackageSchema.validate(data)
  }
}
const createPackageSchema = yup
  .object({
    name: yup.string().required('Package name is required.'),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdatePackage
 * @property {String} name
 */
export class UpdatePackage {
  static schema() {
    return updatePackageSchema
  }
  static validate(data) {
    return updatePackageSchema.validate(data)
  }
}
const updatePackageSchema = yup
  .object({
    name: yup.string().required('Package name is required.'),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdatePackageRequest
 * @property {UpdatePackage} body new values for package
 * @property {Object} params
 * @property {Number} params.id package to update
 */
export class UpdatePackageRequest {
  static schema() {
    return updatePackageSchema
  }
  static validate(data) {
    return updatePackageRequestSchema.validate(data)
  }
}
const updatePackageRequestSchema = yup.object({
  body: updatePackageSchema,
  params: yup.object({
    id: yup.number().required(),
  }),
})
