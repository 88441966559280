import { useRouteError } from 'react-router-dom'
import { Button } from '../../components'

import styles from './no-match.module.scss'

export function NoMatch({ errorStatus = '', errorStatusText = '' }) {
  const error = useRouteError()
  function goBack() {
    window.history.back()
  }
  return (
    <div className={styles.error_page}>
      <h1>Oops. An error occured.</h1>
      {errorStatus && errorStatusText ? (
        <p>{`${errorStatus}: ${errorStatusText}`}</p>
      ) : (
        <p>{`${error?.status}: ${error?.statusText}`}</p>
      )}
      <Button text="Go Back" variant="secondary" onClick={goBack} />
    </div>
  )
}
