import { useState } from 'react'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../../axiosInstance'
import styles from '../packages-edit.module.scss'
import { LoadingSpinner, DownloadIcon, CheckMarkIcon } from '../../../../components'

export function FileListing({ file }) {
  const [loading, setLoading] = useState(false)

  async function handleDownload() {
    setLoading(true)
    const fileName = file.file_name
    await axiosInstance
      .get(`package-files/${file.id}/downloadFile`, { responseType: 'blob' })
      .then((res) => {
        downloadScormPackage(res, fileName.substring(fileName.indexOf('/'))) // remove subdirectory from file name
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
      .finally(() => setLoading(false))
  }

  return (
    <table className={styles.file_listing}>
      <tbody>
        <tr>
          <td>
            <CheckMarkIcon />
          </td>
          <td>{file.file_name}</td>
          <td>Last modified: {new Date(file.updated_at).toDateString()}</td>
          <td>
            <button onClick={handleDownload} disabled={loading}>
              {loading ? <LoadingSpinner /> : <DownloadButton />}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function downloadScormPackage(res, fileName) {
  const url = URL.createObjectURL(res.data)
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = fileName
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
  URL.revokeObjectURL(url)
}

function DownloadButton() {
  return (
    <>
      <p>
        <span>
          <DownloadIcon />
        </span>{' '}
        Download
      </p>
    </>
  )
}
