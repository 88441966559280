import yup from '../customYup.js'

/**
 * @typedef {Object} CreateProductType
 * @property {String} name
 */
export class CreateProductType {
  static schema() {
    return createProductTypeSchema
  }
  static validate(data) {
    return createProductTypeSchema.validate(data)
  }
}
const createProductTypeSchema = yup
  .object({
    name: yup.string().normalize().notNullOrWhiteSpace().required('Product type name is required.'),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateProductType
 * @property {String} name
 */
export class UpdateProductType {
  static schema() {
    return updateProductTypeSchema
  }
  static validate(data) {
    return updateProductTypeSchema.validate(data)
  }
}
const updateProductTypeSchema = yup
  .object({
    name: yup.string().normalize().notNullOrWhiteSpace().required('Product type name is required.'),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateProductTypeRequest
 * @property {UpdateAccount} body new values for product type
 * @property {Object} params
 * @property {String} params.productTypeName current product type name to update
 */
export class UpdateProductTypeRequest {
  static schema() {
    return updateProductTypeSchema
  }
  static validate(data) {
    return updateProductTypeRequestSchema.validate(data)
  }
}
const updateProductTypeRequestSchema = yup.object({
  body: updateProductTypeSchema,
  params: yup.object({
    productTypeName: yup.string().required('Product type name is required.'),
  }),
})
