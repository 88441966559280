import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { UserResetPasswordRequest } from 'common/schema/*'
import { axiosInstance } from '../../axiosInstance'

import { Button, Link, PasswordInput } from '../../components'

import styles from './reset-password.module.scss'

export function ResetPassword() {
  const { userId, resetPasswordToken } = useParams()

  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      resetPasswordToken,
      userId: Number(userId),
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: UserResetPasswordRequest.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  async function handleSubmit(values) {
    await axiosInstance
      .post('reset-user-password', values)
      .then(() => {
        toast.success('Your account password has been updated successfully!')
        setSuccess(true)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div className={styles.reset_password_page}>
      <h1>Dialectic Skill Stream</h1>
      {success ? (
        <>
          <h2>Success!</h2>
          <h4>You can now log in with your credentials.</h4>
          <div className={styles.link_container}>
            <Link href="/login">Log in</Link>
          </div>
        </>
      ) : (
        <>
          <h2>Reset Your Password</h2>
          <h4>Enter a new password for your account</h4>
          <p>Note that the password you create must be at least 14 characters in length.</p>

          <form onSubmit={formik.handleSubmit}>
            <PasswordInput
              label="New Password"
              name="newPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasErrors={formik.touched.newPassword && formik.errors.newPassword}
              errorMessage={formik.errors.newPassword}
            />

            <PasswordInput
              label="Confirm New Password"
              name="confirmNewPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasErrors={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
              errorMessage={formik.errors.confirmNewPassword}
            />
            <div className={styles.submit_container}>
              <Button text="Submit" type="submit" />
            </div>
          </form>
        </>
      )}
    </div>
  )
}
