import { useEffect, useState, useMemo } from 'react'
import { axiosInstance } from '../../../axiosInstance'

import { Table, Link, Loading, TrashIcon, EditIcon } from '../../../components'

export function AccountsList() {
  const [accountsData, setAccountsData] = useState([])
  const [sorting, setSorting] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [totalRecords, setTotalRecords] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(true)

  async function accountsList(sorting, searchFilter, pagination) {
    const { id: sortingColumn = '', desc = false } = sorting[0] || []
    const sortingOrder = desc ? 'desc' : 'asc'
    await axiosInstance
      .post('accounts/list', {
        skip: pagination.pageIndex * pagination.pageSize,
        take: pagination.pageSize,
        sort: [{ column: sortingColumn || 'accounts.id', order: sortingOrder }],
        search: searchFilter,
      })
      .then((res) => {
        setAccountsData(res.data.results)
        setTotalRecords(res.data.resultCount)
      })
      .catch((err) => console.log(`Error: ${err.response.data}`))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    accountsList(sorting, searchFilter, pagination)
  }, [sorting, searchFilter, pagination])

  useEffect(() => {
    setPagination({
      ...pagination,
      pageIndex: 0,
    })
  }, [sorting, searchFilter])

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'organization_name',
        header: 'Organization Name',
        accessorKey: 'organization_name',
      },
      {
        id: 'Edit',
        accessorKey: 'id',
        header: 'Edit',
        cell: (info) => (
          <Link href={`${info.getValue()}`}>
            <EditIcon />
          </Link>
        ),
        enableSorting: false,
      },
      {
        id: 'Delete',
        accessorKey: 'id',
        header: 'Delete',
        cell: (info) => (
          <Link href={`${info.getValue()}/delete`}>
            <TrashIcon />
          </Link>
        ),
        enableSorting: false,
      },
    ],
    []
  )

  if (loading) return <Loading />

  return (
    <div>
      <div className="admin_list_page_header">
        <h1>Manage Accounts</h1>
        <Link href="create">Add Account</Link>
      </div>
      <Table
        data={accountsData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        pagination={pagination}
        setPagination={setPagination}
        totalRecords={totalRecords}
        hasDeleteButton={true}
      />
    </div>
  )
}
