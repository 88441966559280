import yup from '../customYup.js'

/**
 * @typedef {Object} ListOptions
 * @property {number} skip
 * @property {number} take
 * @property {{column: string, order: asc|desc, nulls: first|last}[]} sort
 * @property {string} search
 */
export class ListOptions {
  static schema() {
    return listOptionsSchema
  }
  static validate(data) {
    return listOptionsSchema.validate(data)
  }
}

const listOptionsSchema = yup.object({
  skip: yup.number().default(0).min(0).optional(),
  take: yup.number().default(0).min(0).optional(),
  sort: yup
    .array()
    .of(
      yup.object({
        column: yup.string().required(),
        order: yup.string().default('asc').lowercase().oneOf(['asc', 'desc']).required(),
        nulls: yup
          .string()
          .when('order', {
            is: 'desc',
            then: (schema) => schema.default('first'),
            otherwise: (schema) => schema.default('last'),
          })
          .lowercase()
          .oneOf(['first', 'last']),
      })
    )
    .min(1)
    .optional(),
  search: yup.string().default(''),
})

export class PackageListOptions {
  static schema() {
    return packageListOptionsSchema
  }
  static validate(data) {
    return packageListOptionsSchema.validate(data)
  }
}

// If belongsToProgram = [], will default to include all programs
const packageListOptionsSchema = listOptionsSchema.shape({
  belongsToProgram: yup.array().of(yup.number()).default([]),
  absentFromProgram: yup.array().of(yup.number()).default([]),
})
