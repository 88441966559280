import yup from '../customYup.js'

/**
 * @typedef {Object} CreateSubscription
 * @property {Number} accountId
 * @property {Number} programId
 * @property {Number} lastUpdatedBy
 * @property {Date} startsAt
 * @property {Date} endsAt
 * @property {Number} seats
 */
export class CreateSubscription {
  static schema() {
    return createSubscriptionSchema
  }
  static validate(data) {
    return createSubscriptionSchema.validate(data)
  }
}

const createSubscriptionSchema = yup
  .object({
    accountId: yup.number().required('Account is required.'),
    programId: yup.number().required('Program is required.'),
    startsAt: yup.date().required('Start date for the subscription is required.'),
    endsAt: yup
      .date()
      .required('Expiry date for the subscription is required.')
      .min(yup.ref('startsAt'), 'Expiry Date cannot be earlier than the Start Date.'),
    seats: yup.number().positive('Seat count must be a positive number.').required('Seat number is required.'),
    // included here so it does not get stripped out, the value will be inserted on the server side (not the request body)
    lastUpdatedBy: yup.number(),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateSubscription
 * @property {Number} accountId
 * @property {Number} programId
 * @property {Number} lastUpdatedBy
 * @property {Date} startsAt
 * @property {Date} endsAt
 * @property {Number} seats
 */
export class UpdateSubscription {
  static schema() {
    return updateSubscriptionSchema
  }
  static validate(data) {
    return updateSubscriptionSchema.validate(data)
  }
}

const updateSubscriptionSchema = yup
  .object({
    startsAt: yup.date(),
    endsAt: yup.date().min(yup.ref('startsAt'), 'Expiry Date cannot be earlier than the Start Date.'),
    seats: yup.number().positive('Seat count must be a positive number.').required('Seat count is required.'),
    // included here so it does not get stripped out, the value will be inserted on the server side (not the request body)
    lastUpdatedBy: yup.number(),
  })
  .noUnknown()
  .fieldRequired()

/**
 * @typedef {Object} UpdateSubscriptionRequest
 * @property {UpdateUser} body new values for subscription
 * @property {Number} lastUpdatedBy user id of user making the update
 * @property {Object} params
 * @property {Number} params.id subscription to update
 */
export class UpdateSubscriptionRequest {
  static schema() {
    return updateSubscriptionSchema
  }
  static validate(data) {
    return updateSubscriptionRequestSchema.validate(data)
  }
}
const updateSubscriptionRequestSchema = yup.object({
  body: updateSubscriptionSchema,
  user: yup.object({
    id: yup.number().required(),
  }),
  params: yup.object({
    id: yup.number().required(),
  }),
})
