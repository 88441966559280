import { LoadingSpinner } from '..'
import styles from './downloading.module.scss'

export function Downloading() {
  return (
    <div className={styles.downloading_screen}>
      <h2>Downloading ... Please wait.</h2>
      <LoadingSpinner />
    </div>
  )
}
