import { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { Loading, AccountProgramCard, Sidebar } from '../../components'
import { axiosInstance } from '../../axiosInstance'

import styles from './account.module.scss'

export function Account() {
  const [accountData, setAccountData] = useState({})
  const [loading, setLoading] = useState(true)

  async function getAccount() {
    await axiosInstance
      .get('accounts/client-account')
      .then((res) => {
        setAccountData(res.data)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAccount()
  }, [])

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(accountData?.resources, { ADD_ATTR: ['target'] }),
  })

  if (loading) return <Loading />

  return (
    <div className={styles.account_page}>
      <div className={styles.content}>
        <h1>Active Programs</h1>
        {accountData.subscriptions
          .filter((sub) => new Date(sub.ends_at).getTime() >= new Date().getTime())
          .map((sub) => (
            <AccountProgramCard
              key={sub.program_id}
              programId={sub.program_id}
              name={sub.program_name}
              product={sub.program_product}
              description={sub.program_description}
              featuredImageUrl={sub.program_featured_image_url}
              seats={sub.seats}
              endsAt={sub.ends_at}
            />
          ))}
      </div>
      <Sidebar>
        <h3>Account Resources</h3>
        <div dangerouslySetInnerHTML={sanitizedData()} />
      </Sidebar>
    </div>
  )
}
