import styles from './loading-spinner.module.scss'
import spinner from './loading-spinner.gif'

export function LoadingSpinner() {
  return (
    <div className={styles.spinner_container}>
      <img src={spinner} className={styles.loading_spinner} alt="Loading..." />
    </div>
  )
}
