import { useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../axiosInstance'
import { UserResetPasswordLinkRequest } from 'common/schema/*'

import { Button, TextInput, Link } from '../../components'

import styles from './reset-password.module.scss'

export function RequestResetPassword() {
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: UserResetPasswordLinkRequest.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  async function handleSubmit(values) {
    await axiosInstance
      .post('send-reset-password-link', values)
      .then(() => {
        toast.success('Your request has been processed.')
        setSuccess(true)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div className={styles.reset_password_page}>
      <h1>Dialectic Skill Stream</h1>
      {success ? (
        <>
          <h2>Thank you!</h2>
          <h4>If an account with this email address was found, a password reset link has been sent to it.</h4>
          <div className={styles.link_container}>
            <Link href="/login">Back to log in</Link>
          </div>
        </>
      ) : (
        <>
          <h2>Reset Password Request</h2>
          <h4>Enter your email address where the reset password link will be sent.</h4>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              label="Email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasErrors={formik.touched.email && formik.errors.email}
              errorMessage={formik.errors.email}
            />
            <div className={styles.submit_container}>
              <Button text="Submit" type="submit" />
              <Link href="/login" variant="textLink">
                Back to log in
              </Link>
            </div>
          </form>
        </>
      )}
    </div>
  )
}
