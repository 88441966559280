import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { CreateUser } from 'common/schema/*'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput, SelectInput } from '../../../components'

export function UsersCreate() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      account_id: '',
      role: 'user',
    },
    validationSchema: CreateUser.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const [accountsData, setAccountsData] = useState([])

  useEffect(() => {
    async function getAccounts() {
      await axiosInstance
        .get('accounts')
        .then((res) => setAccountsData(res.data.results))
        .catch((err) => console.log(`Error: ${err.response.data}`))
    }
    getAccounts()
  }, [])

  async function handleSubmit(values) {
    await axiosInstance
      .post('users/create', values)
      .then(() => {
        toast.success('User created successfully!')
        navigate('/admin/users')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Add User</h1>
        <form className="admin_form" onSubmit={formik.handleSubmit}>
          <TextInput
            label="Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <TextInput
            label="Email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.email && formik.errors.email}
            errorMessage={formik.errors.email}
          />
          <SelectInput
            label="Account"
            name="account_id"
            id="account_id"
            value={formik.values['account_id'] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.account_id && formik.errors.account_id}
            errorMessage={formik.errors.account_id}
          >
            {accountsData.map((account) => (
              <option key={account.id} value={account.id}>
                {account.organization_name}
              </option>
            ))}
          </SelectInput>
          <SelectInput
            label="Role"
            name="role"
            id="role"
            value={formik.values['role']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasErrors={formik.touched.role && formik.errors.role}
            errorMessage={formik.errors.role}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </SelectInput>
          <div className="submit_container">
            <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  )
}
