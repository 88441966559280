import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { UpdatePackage } from 'common/schema/*'
import { SCORM_12, SCORM_2004, xAPI, ENGLISH, FRENCH } from 'common/constants'
import { toast } from 'react-toastify'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput, Tab, Tabs, TabList, TabPanel } from '../../../components'
import { ScormPackage } from './components'

import { NoMatch } from '../../../pages'

import styles from './packages-edit.module.scss'

export function PackagesEdit() {
  const navigate = useNavigate()
  const { packageId } = useParams()

  const [packageFiles, setPackageFiles] = useState([])
  const [refreshToggle, setRefreshToggle] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: UpdatePackage.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const [axiosError, setAxiosError] = useState(null)

  async function getPackage() {
    await axiosInstance
      .get(`packages/${packageId}`)
      .then((res) => {
        const packageLesson = res.data
        formik.setFieldValue('name', packageLesson.name)
        setPackageFiles(packageLesson.packageFiles)
      })
      .catch((err) => {
        console.log(err)
        setAxiosError(err.response.status)
      })
  }

  useEffect(() => {
    getPackage()
  }, [packageId, refreshToggle])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`packages/${packageId}/update`, values)
      .then(() => {
        toast.success('Package updated successfully!')
        navigate(`/admin/packages`)
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Manage Package</h1>
        <h2>{formik.values['name']}</h2>
        <Tabs>
          <TabList>
            <Tab>Package Details</Tab>
            <Tab>SCORM 2004</Tab>
            <Tab>SCORM 1.2</Tab>
            <Tab>xAPI</Tab>
          </TabList>
          <TabPanel>
            <form className="admin_form" onSubmit={formik.handleSubmit}>
              <TextInput
                label="Package Name"
                name="name"
                value={formik.values['name']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasErrors={formik.touched.name && formik.errors.name}
                errorMessage={formik.errors.name}
              />
              <div className="submit_container">
                <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting} />
              </div>
            </form>
          </TabPanel>
          <TabPanel>
            <div className={styles.package_file_form_container}>
              <ScormPackage
                packageId={packageId}
                packageFile={packageFiles.filter((file) => file.format === SCORM_2004 && file.language === ENGLISH)[0]}
                format={SCORM_2004}
                language={ENGLISH}
                refreshToggle={refreshToggle}
                setRefreshToggle={setRefreshToggle}
              />
              <ScormPackage
                packageId={packageId}
                packageFile={packageFiles.filter((file) => file.format === SCORM_2004 && file.language === FRENCH)[0]}
                format={SCORM_2004}
                language={FRENCH}
                refreshToggle={refreshToggle}
                setRefreshToggle={setRefreshToggle}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.package_file_form_container}>
              <ScormPackage
                packageId={packageId}
                packageFile={packageFiles.filter((file) => file.format === SCORM_12 && file.language === ENGLISH)[0]}
                format={SCORM_12}
                language={ENGLISH}
                refreshToggle={refreshToggle}
                setRefreshToggle={setRefreshToggle}
              />
              <ScormPackage
                packageId={packageId}
                packageFile={packageFiles.filter((file) => file.format === SCORM_12 && file.language === FRENCH)[0]}
                format={SCORM_12}
                language={FRENCH}
                refreshToggle={refreshToggle}
                setRefreshToggle={setRefreshToggle}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.package_file_form_container}>
              <ScormPackage
                packageId={packageId}
                packageFile={packageFiles.filter((file) => file.format === xAPI && file.language === ENGLISH)[0]}
                format={xAPI}
                language={ENGLISH}
                refreshToggle={refreshToggle}
                setRefreshToggle={setRefreshToggle}
              />
              <ScormPackage
                packageId={packageId}
                packageFile={packageFiles.filter((file) => file.format === xAPI && file.language === FRENCH)[0]}
                format={xAPI}
                language={FRENCH}
                refreshToggle={refreshToggle}
                setRefreshToggle={setRefreshToggle}
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}
