import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { UpdateAccount } from 'common/schema/*'

import { axiosInstance } from '../../../axiosInstance'
import { Button, TextInput, RichTextInput, Link, Table, Tab, Tabs, TabList, TabPanel } from '../../../components'

import { NoMatch } from '../../../pages'
import { toast } from 'react-toastify'

import styles from './accounts-edit.module.scss'

export function AccountsEdit() {
  const navigate = useNavigate()
  const { accountId } = useParams()

  const formik = useFormik({
    initialValues: {
      organization_name: '',
      resources: '',
    },
    validationSchema: UpdateAccount.schema(),
    onSubmit: (values) => handleSubmit(values),
  })

  const [richTextValue, setRichTextValue] = useState('')
  const [accountUsers, setAccountUsers] = useState([])
  const [accountSubscriptions, setAccountSubscriptions] = useState([])
  const [isArchived, setIsArchived] = useState(false)
  const [axiosError, setAxiosError] = useState(null)

  useEffect(() => {
    async function getAccount() {
      await axiosInstance
        .get(`accounts/${accountId}`)
        .then((res) => {
          const { organization_name, resources, users, subscriptions, deleted_at } = res.data
          formik.setFieldValue('organization_name', organization_name)
          formik.setFieldValue('resources', resources)
          setRichTextValue(resources)
          setAccountUsers(users)
          setAccountSubscriptions(subscriptions)
          if (deleted_at) {
            setIsArchived(true)
          }
        })
        .catch((err) => {
          console.log(err)
          setAxiosError(err.response.status)
        })
    }
    getAccount()
  }, [accountId])

  useEffect(() => {
    formik.setFieldValue('resources', richTextValue)
  }, [richTextValue])

  async function handleSubmit(values) {
    await axiosInstance
      .post(`accounts/${accountId}/update`, values)
      .then(() => {
        toast.success('Account updated successfully!')
        navigate('/admin/accounts')
      })
      .catch((err) => toast.error(`Error: ${err.response.data}`))
  }

  if (axiosError) {
    if (axiosError === 404) {
      return <NoMatch errorStatus="404" errorStatusText="The requested resource does not exist." />
    }
    return <NoMatch errorStatus="500" errorStatusText="Something went wrong." />
  }

  return (
    <div>
      <div className="admin_form_page">
        <h1>Manage Account</h1>
        <h2>{formik.values['organization_name']}</h2>
        {isArchived && (
          <p>
            <b>*This is an archived account</b>
          </p>
        )}
        <Tabs>
          <TabList>
            <Tab>Account Details</Tab>
            <Tab>Users</Tab>
            <Tab>Programs</Tab>
          </TabList>
          <TabPanel>
            <form className="admin_form" onSubmit={formik.handleSubmit}>
              <TextInput
                label="Organization Name"
                name="organization_name"
                value={formik.values['organization_name']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasErrors={formik.touched.organization_name && formik.errors.organization_name}
                errorMessage={formik.errors.organization_name}
              />
              <RichTextInput label="Resources" id="resources" value={richTextValue} onChange={setRichTextValue} />
              <div className="submit_container">
                <Button type="submit" text="Save" variant="primary" disabled={formik.isSubmitting || isArchived} />
              </div>
            </form>
          </TabPanel>
          <TabPanel>
            <UsersTable accountUsers={accountUsers} />
          </TabPanel>
          <TabPanel>
            <SubscriptionsTable accountSubscriptions={accountSubscriptions} isArchived={isArchived} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

function UsersTable({ accountUsers }) {
  const columns = useMemo(
    () => [
      {
        id: 'userId',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        id: 'email',
        header: 'Email',
        accessorKey: 'email',
      },
      {
        id: 'role',
        header: 'Role',
        accessorKey: 'role',
      },
      {
        id: 'editButton',
        header: '',
        accessorKey: 'id',
        cell: (info) => <Link href={`/admin/users/${info.getValue()}`}>Edit</Link>,
        enableSorting: false,
      },
    ],
    []
  )
  return <Table data={accountUsers} columns={columns} />
}

function SubscriptionsTable({ accountSubscriptions, isArchived }) {
  const columns = useMemo(
    () => [
      {
        id: 'subscriptionId',
        header: 'ID',
        accessorKey: 'id',
      },
      {
        id: 'programName',
        header: 'Program',
        accessorKey: 'program_name',
      },
      {
        id: 'startDate',
        header: 'Start Date',
        accessorKey: 'starts_at',
        cell: (info) => new Date(info.getValue()).toLocaleDateString('en-CA'),
      },
      {
        id: 'expiryDate',
        header: 'Expiry Date',
        accessorKey: 'ends_at',
        cell: (info) => new Date(info.getValue()).toLocaleDateString('en-CA'),
      },
      {
        id: 'seats',
        header: 'Seats',
        accessorKey: 'seats',
      },
      {
        id: 'editButton',
        header: '',
        accessorKey: 'id',
        cell: (info) => <Link href={`subscriptions/${info.getValue()}/edit`}>Edit</Link>,
        enableSorting: false,
      },
    ],
    []
  )
  return (
    <div className={styles.account_subscriptions}>
      {!isArchived && <Link href="subscriptions/create">Add New Subscription</Link>}
      {accountSubscriptions.length > 0 && <Table data={accountSubscriptions} columns={columns} />}
    </div>
  )
}
