import yup from '../customYup.js'

/**
 * @typedef {Object} CreateAccount
 * @property {String} organization_name
 * @property {String} resources
 */
export class CreateAccount {
  static schema() {
    return createAccountSchema
  }
  static validate(data) {
    return createAccountSchema.validate(data)
  }
}
const createAccountSchema = yup
  .object({
    organization_name: yup.string().normalize().notNullOrWhiteSpace().required('Organization name is required.'),
    resources: yup.string().optional().default(''),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateAccount
 * @property {String} organization_name
 * @property {String} resources
 */
export class UpdateAccount {
  static schema() {
    return updateAccountSchema
  }
  static validate(data) {
    return updateAccountSchema.validate(data)
  }
}
const updateAccountSchema = yup
  .object({
    organization_name: yup.string().normalize().notNullOrWhiteSpace().required('Organization name is required.'),
    resources: yup.string().optional().default(''),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdateAccountRequest
 * @property {UpdateAccount} body new values for account
 * @property {Object} params
 * @property {Number} params.id account to update
 */
export class UpdateAccountRequest {
  static schema() {
    return updateAccountSchema
  }
  static validate(data) {
    return updateAccountRequestSchema.validate(data)
  }
}
const updateAccountRequestSchema = yup.object({
  body: updateAccountSchema,
  params: yup.object({
    id: yup.number().required(),
  }),
})
