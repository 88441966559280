import yup from '../customYup.js'
import { SCORM_12, SCORM_2004, xAPI, ENGLISH, FRENCH } from '../../constants.js'

/**
 * @typedef {Object} CreatePackageFileRequest
 * @property {Object} params
 * @property {Object} query
 * @property {Object} file
 */
export class CreatePackageFileRequest {
  static schema() {
    return createPackageFileRequestSchema
  }
  static validate(data) {
    return createPackageFileRequestSchema.validate(data)
  }
}
const createPackageFileRequestSchema = yup
  .object({
    params: yup.object({
      packageId: yup.number().required(),
    }),
    query: yup.object({
      language: yup.string().oneOf([ENGLISH, FRENCH]).required(),
      format: yup.string().oneOf([SCORM_2004, SCORM_12, xAPI]),
    }),
    file: yup.object(),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdatePackageFile
 * @property {Blob} file
 */
export class UpdatePackageFile {
  static schema() {
    return updatePackageFileSchema
  }
  static validate(data) {
    return updatePackageFileSchema.validate(data)
  }
}
const updatePackageFileSchema = yup
  .object({
    file: yup.object(),
  })
  .noUnknown()

/**
 * @typedef {Object} UpdatePackageFileRequest
 * @property {UpdatePackageFile} body
 * @property {Object} params
 * @property {Number} params.packageFileId
 */
export class UpdatePackageFileRequest {
  static schema() {
    return updatePackageFileRequestSchema
  }
  static validate(data) {
    return updatePackageFileRequestSchema.validate(data)
  }
}
const updatePackageFileRequestSchema = yup.object({
  body: updatePackageFileSchema,
  params: yup.object({
    packageFileId: yup.number().required(),
  }),
})

export class DownloadPackageFiles {
  static schema() {
    return downloadPackageFilesSchema
  }
  static validate(data) {
    return downloadPackageFilesSchema.validate(data)
  }
}

//TODO change scormFormat to format; will need to update route and FE
const downloadPackageFilesSchema = yup.object({
  packageIds: yup.array().of(yup.number().integer()).required(),
  scormFormat: yup.string().oneOf([SCORM_2004, SCORM_12, xAPI]).required(),
  language: yup.string().oneOf([ENGLISH, FRENCH]).required(),
})
